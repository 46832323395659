/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import LogoPPA from '../assets/images/lp/logotipo_atrio.svg'

import { 
  sucessContent,
  logoImg, 
}  from '../assets/styles/LP.styles'

const ContentSection = () => {
  return (
    <section css={sucessContent}>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div css={logoImg}><img src={LogoPPA} alt="Pré-Vestibular PPA" title="Pré-Vestibular PPA" /></div>
            <div style={{ marginTop: 56 }}>
              <iframe src="https://forms.monday.com/forms/embed/31a4e8ea1a921fb3acfdf5237844d063?r=use1" style={{ width: '100%', height: 1700, border: 'none' }}  />
            </div>
            </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContentSection;
